.top__companies {
  padding: auto;
}

@media only screen and (max-width: 992px) {
  
}

@media only screen and (max-width: 768px) {
  .top__companies {
    padding: auto;
  }
}

@media only screen and (max-width: 576px) {
  .top__companies-img {
    display: none;
  }
  .top__companies {
    text-align: center;
  }
}
