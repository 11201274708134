.footer {
  background: #216e14;
  padding-top: 50px;
  padding-bottom: 15px;
}

.tel-link {
  text-decoration: none;
}

.add__link:hover {
  color: #f9a826;
}

.footer__logo i {
  color: #fff;
  font-weight: 400;
}

.footer__logo-content {
  color: #fff;
  margin-top: 15px;
  line-height: 30px;
  font-weight: 300;
  text-align: justify;
  margin-right: 50px;
}

.services_links {
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  transition: 0.3s;
}

.services_links:hover {
  color: #f9a826;
}

.quick__link {
  border: none !important;
  background: transparent !important;
}

.social__link a {
  text-decoration: none;
  color: #fff;
  padding: 5px;
}

.social__link a:hover {
  text-decoration: none;
  color: rgb(140, 210, 160);
  padding: 5px;
}

.social__link a i {
  border-radius: 10px;
  font-size: 25px;
}

.quick__link a {
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  transition: 0.3s;
}

.quick__link a:hover {
  color: #f9a826;
}

.footer__link-title {
  color: #fff;
}

.office__info {
  color: #fff;
  font-weight: 300;
}

.footer__bottom {
  border-top: 1px solid #7c8a976a;
}

.copy_right {
  color: #fff;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .logo {
    margin-left: 14%;
  }
  .footer {
    text-align: center;
  }

  .footer__logo-content {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }

  .logo {
    margin-left: 23%;
  }

  .footer__logo-content {
    font-size: 0.8rem;
    margin-right: 0px;
    text-align: center;
  }

  .quick__link,
  .office__info {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .footer__link-title {
    font-size: 1rem;
    margin-top: 15px;
  }

  .footer__bottom p {
    font-size: 0.7rem;
  }
  
}

@media only screen and (max-width: 360px) {
  .footer {
    text-align: center;
  }

  .logo {
    margin-left: 16%;
  }

  .footer__logo-content {
    margin-right: 0px;
    text-align: center;
  }

  .quick__link,
  .office__info {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

}

@media only screen and (max-width: 548px) {
  .logo{
    margin-left: 20%;
  }
}