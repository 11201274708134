
  .section__company {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px;
    background-color: #216e14;
    color: #fff;
  }
  
  .single__frame {
    border: 1px solid #7c8a972d;
    border-radius: 4px;
  }
  
  .single__frame-img {
    position: relative;
    cursor: pointer;
  }
  
  .single__frame-img img {
    padding: 10px;
  }
  
  @media only screen and (max-width: 992px) {
    .section__description {
      font-size: 12px;
    }
    .section__company {
      font-size: 14px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    
  }
  