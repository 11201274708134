
/* ============ header top style =========== */

.header__middle {
  padding: 10px 0px;
}

.logo {
  text-align: center;
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  color: #216e14;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;
  color: #216e14;
}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #216e14;
}

.header__location-content h6 {
  font-weight: 600;
}

.header__btn {
  background: #216e14 !important;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: #000;
  justify-content: flex-end;
  font-size: 0.9rem;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 2px;
}

.header__btn a:hover {
  color: #216e14;
}

/* ================ main navbar styles =========== */

.main__navbar {
  padding: 15px 0px;
  background: #216e14;
}

.mobile__menu i {
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
}

.nav__item {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item:hover {
  color: #f9a826;
}

.nav__active {
  color: #f9a826;
}

@media only screen and (max-width: 992px) {
  .header__middle {
    margin-right: 33%;
  }
  
  .mobile__menu {
    display: block;
  }

  .navigation {
    background: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: rgba(0, 0, 0, 0.837);
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #216e14;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }

}

@media only screen and (max-width: 768px) {
  .header__middle {
    margin-right: 33%;
  }
  
  .mobile__menu {
    display: block;
  }

  .navigation {
    background: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: rgba(0, 0, 0, 0.837);
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #216e14;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }
}

@media only screen and (max-width: 360px) {
  .header__middle {
    margin-right: 28%;
  }
}