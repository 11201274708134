
.social__link-icon a {
  text-decoration: none;
  color: #216e14;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 5px;
}

.map {
  width: 650px;
	height: 400px;
}

@media only screen and (max-width: 992px) {
  .map {
    display: block;
    width: 350px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .map {
    display: block;
    width: 350px;
    padding-bottom: 50px;
  }
}
