

.NexusRow {
    margin-top: -100px;
    position: relative;
  }
  
  .NexusColumn {
    margin-top: 27px;
    float: left;
    width: 28%;
    padding: 20px;
    margin-left: 80px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #6c7e8f;
    text-align: center;
    border-radius: 5px;
    background-color: azure;
    line-height: 25px;
    height: 260px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    font-size: 15px;
  }
  .PlacementsColumn {
    float: left;
    width: 28%;
    padding: 20px;
    margin-left: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #6c7e8f;
    text-align: center;
    border-radius: 5px;
    background-color: azure;
    line-height: 25px;
    height: 310px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    font-size: 15px;
  }
  .TrainingColumn {
    margin-top: 27px;
    float: left;
    width: 28%;
    padding: 20px;
    margin-left: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #6c7e8f;
    text-align: center;
    border-radius: 5px;
    background-color: azure;
    line-height: 25px;
    height: 260px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    font-size: 15px;
  }

  .NexusColumn:hover, .PlacementsColumn:hover, .TrainingColumn:hover {
    box-shadow: 0px 5px 10px 0px  #216e14;
  }

  /* Clear floats after the columns */
  .NexusRow:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .NexusColumn h2, .PlacementsColumn h2, .TrainingColumn h2 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 5px;
    font-size: 25px;
    color: black;
  }

  .NexusColumn h1, .PlacementsColumn h1, .TrainingColumn h1 {
    font-size: 50px;
  }
  
  @media only screen and (max-width: 992px) {
    .NexusColumn, .TrainingColumn {
      margin-left: 47px;
      line-height: 22px;
      height: 280px;
      font-size: 14px;
    }
    .PlacementsColumn {
      line-height: 22px;
      height: 325px;
      font-size: 14px;
    }
    
  }

  @media only screen and (max-width: 768px) {
    .NexusRow {
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        background-color: #fff;
        display: block;
        width: fit-content;
        animation: transfromX ease-in 0.5s;
      }
      .NexusColumn, .PlacementsColumn, .TrainingColumn {
        width: 80%;
        padding: 15px;
        margin: 0px;
        position: inherit;
        left: 10%;
        right: 10%;
        bottom: 10%;
        line-height: 15px;
        font-size: 12px;
      }
      .NexusColumn {
        height: 200px;
        margin-top: 20px;
        margin-bottom: 15px;
      }
      .PlacementsColumn {
        height: 220px;
      }
      .TrainingColumn {
        height: 210px;
        margin-top: 15px;
        margin-bottom: 0px;
      }
      
      .NexusColumn h2, .PlacementsColumn h2, .TrainingColumn h2 {
        font-size: 14px;
      }
  }

  /* TOP COMPANIES SECTION */

  .top_companies_section_heading h2 {
    margin-bottom: -30px;
    margin-top: 30px;
    color: #fff;
  }

  .top_companies_section_heading {
    background: linear-gradient(rgba(0, 107, 20, 0.9), rgb(0, 107, 20, 0.9)),
    url("../assets/all-images/top-companies-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
  }
  

  /* LATEST PLACEMENT SECTION */

  .latest_placement{
    margin-top: 100px;
  }

  .latest_placement_section_heading h2 {
    padding: 10px;
    margin-bottom: -30px;
    color: #fff;
    background-color: #216e14;
  }

  .latest_placement_section_heading {
    background: linear-gradient(#fff, #fff),
    url("../assets/all-images/top-companies-bg.jpg");
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    .latest_placement{
      margin-top: 50px;
      margin-bottom: -50px;
    }  
  }
  
  @media only screen and (max-width: 576px) {
    .latest_placement{
      margin-top: 60px;
    }  
  }
  
  @media only screen and (max-width: 360px) {
    .latest_placement{
      margin-top: 50px;
    }
  
  }
  
  @keyframes transformX{
    from{
      transform: translateX(-100px);
    }
    to{
      transform: translateX(0px);
    }
  }