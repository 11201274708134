@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap);
/* ======== google fonts ======== */

/* ======= base style ====== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}

/* ========= pre-define css ========= */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #216e14;
  font-weight: 600;
  margin-bottom: 0;
}

h3,
h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  margin: 0;
  list-style: none;
}

.section__title {
  color: #216e14;
  font-weight: 600;
  font-size: 2rem;
}

.section__subtitle {
  color: #f9a826;
  font-weight: 600;
  font-size: 1.2rem;
}

.section__description {
  color: #7c8a97;
  font-size: 0.9rem;
  line-height: 30px;
}

section {
  padding: 50px 0px;
}

.btn {
  padding: 7px 15px;
  background: #216e14;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.3s;
}

.btn:hover {
  color: #fff !important;
  background: #000cd0;
}

@media only screen and (max-width: 768px) {
  section {
    padding: 30px 0px;
  }

  .section__title {
    font-size: 1.6rem;
  }

  .section__subtitle {
    font-size: 1.1rem;
  }
}


/* ============ header top style =========== */

.header__middle {
  padding: 10px 0px;
}

.logo {
  text-align: center;
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  color: #216e14;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;
  color: #216e14;
}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #216e14;
}

.header__location-content h6 {
  font-weight: 600;
}

.header__btn {
  background: #216e14 !important;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.header__btn a {
  display: flex;
  align-items: center;
  grid-column-gap: 0.4rem;
  -webkit-column-gap: 0.4rem;
          column-gap: 0.4rem;
  text-decoration: none;
  color: #000;
  justify-content: flex-end;
  font-size: 0.9rem;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 2px;
}

.header__btn a:hover {
  color: #216e14;
}

/* ================ main navbar styles =========== */

.main__navbar {
  padding: 15px 0px;
  background: #216e14;
}

.mobile__menu i {
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  grid-column-gap: 2.7rem;
  -webkit-column-gap: 2.7rem;
          column-gap: 2.7rem;
}

.nav__item {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item:hover {
  color: #f9a826;
}

.nav__active {
  color: #f9a826;
}

@media only screen and (max-width: 992px) {
  .header__middle {
    margin-right: 33%;
  }
  
  .mobile__menu {
    display: block;
  }

  .navigation {
    background: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: rgba(0, 0, 0, 0.837);
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #216e14;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }

}

@media only screen and (max-width: 768px) {
  .header__middle {
    margin-right: 33%;
  }
  
  .mobile__menu {
    display: block;
  }

  .navigation {
    background: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: rgba(0, 0, 0, 0.837);
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #216e14;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }
}

@media only screen and (max-width: 360px) {
  .header__middle {
    margin-right: 28%;
  }
}
.footer {
  background: #216e14;
  padding-top: 50px;
  padding-bottom: 15px;
}

.tel-link {
  text-decoration: none;
}

.add__link:hover {
  color: #f9a826;
}

.footer__logo i {
  color: #fff;
  font-weight: 400;
}

.footer__logo-content {
  color: #fff;
  margin-top: 15px;
  line-height: 30px;
  font-weight: 300;
  text-align: justify;
  margin-right: 50px;
}

.services_links {
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  transition: 0.3s;
}

.services_links:hover {
  color: #f9a826;
}

.quick__link {
  border: none !important;
  background: transparent !important;
}

.social__link a {
  text-decoration: none;
  color: #fff;
  padding: 5px;
}

.social__link a:hover {
  text-decoration: none;
  color: rgb(140, 210, 160);
  padding: 5px;
}

.social__link a i {
  border-radius: 10px;
  font-size: 25px;
}

.quick__link a {
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  transition: 0.3s;
}

.quick__link a:hover {
  color: #f9a826;
}

.footer__link-title {
  color: #fff;
}

.office__info {
  color: #fff;
  font-weight: 300;
}

.footer__bottom {
  border-top: 1px solid #7c8a976a;
}

.copy_right {
  color: #fff;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .logo {
    margin-left: 14%;
  }
  .footer {
    text-align: center;
  }

  .footer__logo-content {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }

  .logo {
    margin-left: 23%;
  }

  .footer__logo-content {
    font-size: 0.8rem;
    margin-right: 0px;
    text-align: center;
  }

  .quick__link,
  .office__info {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .footer__link-title {
    font-size: 1rem;
    margin-top: 15px;
  }

  .footer__bottom p {
    font-size: 0.7rem;
  }
  
}

@media only screen and (max-width: 360px) {
  .footer {
    text-align: center;
  }

  .logo {
    margin-left: 16%;
  }

  .footer__logo-content {
    margin-right: 0px;
    text-align: center;
  }

  .quick__link,
  .office__info {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

}

@media only screen and (max-width: 548px) {
  .logo{
    margin-left: 20%;
  }
}
.slider__item {
  max-width: 100%;
  height: 650px;
}

.slider__item-01 {
  background: linear-gradient(#000000b1, #000000b1),
    url(/static/media/slider-1.2c806b4d.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider__item-02 {
  background: linear-gradient(#000000b1, #000000b1),
    url(/static/media/slider-2.fdbb8aa4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider__item-03 {
  background: linear-gradient(#000000b1, #000000b1),
    url(/static/media/slider-3.17e43978.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero__slider {
  overflow: hidden;
}

.slider__content {
  padding-top: 220px;
  text-align: center;
}

.slider__content h1 {
  font-size: 3.2rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .slider__item {
    height: 530px;
    max-width: 100vw;
  }

  .slider__content {
    margin-top: -50px;
  }

  .slider__content h1 {
    font-size: 2rem;
  }

  .slider__content h4 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .slider__item {
    height: 450px;
  }

  .slider__content h1 {
    font-size: 1.4rem;
  }

  .slider__content h4 {
    font-size: 0.9rem;
  }
}



.NexusRow {
    margin-top: -100px;
    position: relative;
  }
  
  .NexusColumn {
    margin-top: 27px;
    float: left;
    width: 28%;
    padding: 20px;
    margin-left: 80px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #6c7e8f;
    text-align: center;
    border-radius: 5px;
    background-color: azure;
    line-height: 25px;
    height: 260px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    font-size: 15px;
  }
  .PlacementsColumn {
    float: left;
    width: 28%;
    padding: 20px;
    margin-left: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #6c7e8f;
    text-align: center;
    border-radius: 5px;
    background-color: azure;
    line-height: 25px;
    height: 310px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    font-size: 15px;
  }
  .TrainingColumn {
    margin-top: 27px;
    float: left;
    width: 28%;
    padding: 20px;
    margin-left: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #6c7e8f;
    text-align: center;
    border-radius: 5px;
    background-color: azure;
    line-height: 25px;
    height: 260px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    font-size: 15px;
  }

  .NexusColumn:hover, .PlacementsColumn:hover, .TrainingColumn:hover {
    box-shadow: 0px 5px 10px 0px  #216e14;
  }

  /* Clear floats after the columns */
  .NexusRow:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .NexusColumn h2, .PlacementsColumn h2, .TrainingColumn h2 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 5px;
    font-size: 25px;
    color: black;
  }

  .NexusColumn h1, .PlacementsColumn h1, .TrainingColumn h1 {
    font-size: 50px;
  }
  
  @media only screen and (max-width: 992px) {
    .NexusColumn, .TrainingColumn {
      margin-left: 47px;
      line-height: 22px;
      height: 280px;
      font-size: 14px;
    }
    .PlacementsColumn {
      line-height: 22px;
      height: 325px;
      font-size: 14px;
    }
    
  }

  @media only screen and (max-width: 768px) {
    .NexusRow {
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        background-color: #fff;
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        -webkit-animation: transfromX ease-in 0.5s;
                animation: transfromX ease-in 0.5s;
      }
      .NexusColumn, .PlacementsColumn, .TrainingColumn {
        width: 80%;
        padding: 15px;
        margin: 0px;
        position: inherit;
        left: 10%;
        right: 10%;
        bottom: 10%;
        line-height: 15px;
        font-size: 12px;
      }
      .NexusColumn {
        height: 200px;
        margin-top: 20px;
        margin-bottom: 15px;
      }
      .PlacementsColumn {
        height: 220px;
      }
      .TrainingColumn {
        height: 210px;
        margin-top: 15px;
        margin-bottom: 0px;
      }
      
      .NexusColumn h2, .PlacementsColumn h2, .TrainingColumn h2 {
        font-size: 14px;
      }
  }

  /* TOP COMPANIES SECTION */

  .top_companies_section_heading h2 {
    margin-bottom: -30px;
    margin-top: 30px;
    color: #fff;
  }

  .top_companies_section_heading {
    background: linear-gradient(rgba(0, 107, 20, 0.9), rgb(0, 107, 20, 0.9)),
    url(/static/media/top-companies-bg.6ba36a41.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
  }
  

  /* LATEST PLACEMENT SECTION */

  .latest_placement{
    margin-top: 100px;
  }

  .latest_placement_section_heading h2 {
    padding: 10px;
    margin-bottom: -30px;
    color: #fff;
    background-color: #216e14;
  }

  .latest_placement_section_heading {
    background: linear-gradient(#fff, #fff),
    url(/static/media/top-companies-bg.6ba36a41.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    .latest_placement{
      margin-top: 50px;
      margin-bottom: -50px;
    }  
  }
  
  @media only screen and (max-width: 576px) {
    .latest_placement{
      margin-top: 60px;
    }  
  }
  
  @media only screen and (max-width: 360px) {
    .latest_placement{
      margin-top: 50px;
    }
  
  }
  
  @-webkit-keyframes transformX{
    from{
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
    }
    to{
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  
  @keyframes transformX{
    from{
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
    }
    to{
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
.about__section-item {
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
}

.about__section-item p i {
  color: #f9a826;
  font-size: 1.1rem;
}

.section__description {
  text-align: justify;
}

@media only screen and (max-width: 992px) {
  .about__section-content h2 {
    font-size: 1.5rem;
  }
  .about__section-item {
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }
  .about__section-item p {
    font-size: 0.6rem;
    margin-bottom: 0;
  }

  .section__description i {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .about__img {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .about__section {
    margin-top: 280px;
  }
}

.testimonial p {
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .testimonial p {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .testimonial h6 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .testimonial img {
    width: 60px !important;
    height: 60px !important;
    object-fit: cover;
  }
}

.top__companies {
  padding: auto;
}

@media only screen and (max-width: 992px) {
  
}

@media only screen and (max-width: 768px) {
  .top__companies {
    padding: auto;
  }
}

@media only screen and (max-width: 576px) {
  .top__companies-img {
    display: none;
  }
  .top__companies {
    text-align: center;
  }
}

.slider__section {
    align-items: center;
    padding: 5px;
}
.top_companies {
    text-align: center;
  }

.top_companies img {
    width: 150px !important;
    height: 150px !important;
  }

  @media only screen and (max-width: 768px) {
  
  }
  
  @media only screen and (max-width: 576px) {
    .top_companies img {
      width: 166px !important;
      height: 166px !important;
      object-fit: cover;
    }
  }
  
  @media only screen and (max-width: 360px) {
    .top_companies img {
      width: 126px !important;
      height: 126px !important;
      object-fit: cover;
      overflow-x: hidden;
    }
  }
.slider__section {
    align-items: center;
    padding: 5px;
}
.placed_students a{
  text-decoration: none;
}
.placed_students {
    text-align: center;
  }

.placed_students button {
    width: 200px !important;
    height: 50px !important;
    background-color: #216e14;
    color: #fff;
    border: none;
  }

.placed_students button:hover{
  background-color: transparent;
  color: #216e14;
  border: solid 1px #216e14;
}

  @media only screen and (max-width: 768px) {
  
  }
  
  @media only screen and (max-width: 576px) {
    .placed_students button {
      width: 150px !important;
      height: 50px !important;
      object-fit: cover;
    }
  }
  
  @media only screen and (max-width: 360px) {
    .placed_students button {
      width: 100% !important;
      height: 50px !important;
      object-fit: cover;
    }
  }
  
@media only screen and (max-width: 992px) {
  .about__page-content h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .about__page-img {
    display: none;
  }
}


.social__link-icon a {
  text-decoration: none;
  color: #216e14;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 5px;
}

.map {
  width: 650px;
	height: 400px;
}

@media only screen and (max-width: 992px) {
  .map {
    display: block;
    width: 350px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .map {
    display: block;
    width: 350px;
    padding-bottom: 50px;
  }
}

.committee__heading {
    padding-top: 50px;
}
  .bloc-tabs {
    display: flex;
  }
  
  .tabs {
    padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    background: white;
    border-bottom: 1px solid transparent;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: #216e14;
  }
  
  button {
    border: none;
    
  }
  .content-tabs {
    flex-grow : 1;
  }
  .content {
    background: white;
    padding: auto;
    width: 100%;
    height: 100%;
    display: none;
    padding-top: 40px;
  }
  
  .active-content {
    display: block;
  }
  
  @media only screen and (max-width: 768px) {
      
  }
  
  @media only screen and (max-width: 576px) {
    .tabs h6{
        font-size: 16px;
      }
  }
  
  @media only screen and (max-width: 360px) {
    .tabs h6{
        font-size: 14px;
      }
  }
.section__name {
  color: #216e14;
}
.section__role {
  border: #7c8a972d 1px solid;
  padding: 10px;
  background-color: #216e14;
  color: #fff;
}

.single__member {
  border: 1px solid #7c8a972d;
  border-radius: 4px;
}

.single__member-social {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  position: absolute;
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: #216e14a6;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 4px;
  transition: 0.5s ease;
  opacity: 0;
}

.single__member-social a i {
  width: 28px;
  height: 28px;
  color: #216e14;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  font-size: 0.9rem;
  font-weight: 600;
}

.single__member-social a {
  text-decoration: none;
  cursor: pointer;
}

.single__member-img {
  position: relative;
  cursor: pointer;
}

.single__member-img img {
  border-radius: 4px;
}

.single__member-img:hover .single__member-social {
  top: 0;
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .section__name {
    font-size: 14px;
  }
  .section__description {
    font-size: 12px;
  }
  .section__role {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  
}

.social__link a {
  text-decoration: none;
  color: #fff;
  align-self: flex-end;
}

.faculty__item {
  border: 1px solid #7c8a9736;
  padding: 20px;
  border-radius: 5px;
}

.faculty__item-info i {
  color: #0a2153;
  font-size: 25px;

}

.faculty__item-btn {
  border-radius: 0;
  border: none;
  outline: none;
  background: #216e14;
  padding: 8px 0px;
}

.faculty__item-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.faculty__item-btn a:hover {
  color: #fff;
}
.faculty__btn-details {
  background: #f9a826;
  border-radius: 4px 4px 4px 4px;
}

.faculty__btn-rent {
  border-radius: 4px;
}

.faculty__item-content h4 {
  font-size: 1.8rem;
  border-top: #7c8a9736 1px solid;
  border-bottom: #7c8a9736 1px solid;
}

.faculty__item-content h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .faculty__item h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .faculty__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .faculty__item-info {
    flex-wrap: wrap;
  }

  .faculty__item-info {
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .faculty__item-btn {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .faculty__item h4 {
    font-size: 1rem;
  }

  .faculty__item h6 {
    font-size: 0.9rem;
  }

  .faculty__item-info {
    font-size: 0.7rem;
  }

  .faculty__item-info i {
    font-size: 0.9rem;
  }

  .faculty__item-btn {
    font-size: 0.8rem;
  }
}

.galleryWrap {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .galleryWrap .single {
    width: 400px;
    cursor: pointer;
  }
  
  .galleryWrap .single img {
    max-width: 100%;
  }
  
  .galleryWrap .single img:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }
body {
    box-sizing: border-box;
  }
  
  * {
    margin: 0;
  }
  
  .App {
    text-align: center;
    padding: 20px;
    font-size: 24px;
  }
  

  .section__company {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px;
    background-color: #216e14;
    color: #fff;
  }
  
  .single__frame {
    border: 1px solid #7c8a972d;
    border-radius: 4px;
  }
  
  .single__frame-img {
    position: relative;
    cursor: pointer;
  }
  
  .single__frame-img img {
    padding: 10px;
  }
  
  @media only screen and (max-width: 992px) {
    .section__description {
      font-size: 12px;
    }
    .section__company {
      font-size: 14px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    
  }
  
.committee__heading {
    padding-top: 50px;
}

.heading__col{
    border-top: 1px solid #216e14;
    border-bottom: 1px solid #216e14;
    padding: 5px;
}

@media only screen and (max-width: 360px) {
    .heading__col h2{
        font-size: 20px;
    }
}
