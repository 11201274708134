.slider__section {
    align-items: center;
    padding: 5px;
}
.top_companies {
    text-align: center;
  }

.top_companies img {
    width: 150px !important;
    height: 150px !important;
  }

  @media only screen and (max-width: 768px) {
  
  }
  
  @media only screen and (max-width: 576px) {
    .top_companies img {
      width: 166px !important;
      height: 166px !important;
      object-fit: cover;
    }
  }
  
  @media only screen and (max-width: 360px) {
    .top_companies img {
      width: 126px !important;
      height: 126px !important;
      object-fit: cover;
      overflow-x: hidden;
    }
  }