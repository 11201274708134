.committee__heading {
    padding-top: 50px;
}

.heading__col{
    border-top: 1px solid #216e14;
    border-bottom: 1px solid #216e14;
    padding: 5px;
}

@media only screen and (max-width: 360px) {
    .heading__col h2{
        font-size: 20px;
    }
}