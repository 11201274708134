.slider__item {
  max-width: 100%;
  height: 650px;
}

.slider__item-01 {
  background: linear-gradient(#000000b1, #000000b1),
    url("../assets/all-images/slider-img/slider-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider__item-02 {
  background: linear-gradient(#000000b1, #000000b1),
    url("../assets/all-images/slider-img/slider-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider__item-03 {
  background: linear-gradient(#000000b1, #000000b1),
    url("../assets/all-images/slider-img/slider-3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero__slider {
  overflow: hidden;
}

.slider__content {
  padding-top: 220px;
  text-align: center;
}

.slider__content h1 {
  font-size: 3.2rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .slider__item {
    height: 530px;
    max-width: 100vw;
  }

  .slider__content {
    margin-top: -50px;
  }

  .slider__content h1 {
    font-size: 2rem;
  }

  .slider__content h4 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .slider__item {
    height: 450px;
  }

  .slider__content h1 {
    font-size: 1.4rem;
  }

  .slider__content h4 {
    font-size: 0.9rem;
  }
}
