.social__link a {
  text-decoration: none;
  color: #fff;
  align-self: flex-end;
}

.faculty__item {
  border: 1px solid #7c8a9736;
  padding: 20px;
  border-radius: 5px;
}

.faculty__item-info i {
  color: #0a2153;
  font-size: 25px;

}

.faculty__item-btn {
  border-radius: 0;
  border: none;
  outline: none;
  background: #216e14;
  padding: 8px 0px;
}

.faculty__item-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.faculty__item-btn a:hover {
  color: #fff;
}
.faculty__btn-details {
  background: #f9a826;
  border-radius: 4px 4px 4px 4px;
}

.faculty__btn-rent {
  border-radius: 4px;
}

.faculty__item-content h4 {
  font-size: 1.8rem;
  border-top: #7c8a9736 1px solid;
  border-bottom: #7c8a9736 1px solid;
}

.faculty__item-content h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .faculty__item h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .faculty__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .faculty__item-info {
    flex-wrap: wrap;
  }

  .faculty__item-info {
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .faculty__item-btn {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .faculty__item h4 {
    font-size: 1rem;
  }

  .faculty__item h6 {
    font-size: 0.9rem;
  }

  .faculty__item-info {
    font-size: 0.7rem;
  }

  .faculty__item-info i {
    font-size: 0.9rem;
  }

  .faculty__item-btn {
    font-size: 0.8rem;
  }
}
