.slider__section {
    align-items: center;
    padding: 5px;
}
.placed_students a{
  text-decoration: none;
}
.placed_students {
    text-align: center;
  }

.placed_students button {
    width: 200px !important;
    height: 50px !important;
    background-color: #216e14;
    color: #fff;
    border: none;
  }

.placed_students button:hover{
  background-color: transparent;
  color: #216e14;
  border: solid 1px #216e14;
}

  @media only screen and (max-width: 768px) {
  
  }
  
  @media only screen and (max-width: 576px) {
    .placed_students button {
      width: 150px !important;
      height: 50px !important;
      object-fit: cover;
    }
  }
  
  @media only screen and (max-width: 360px) {
    .placed_students button {
      width: 100% !important;
      height: 50px !important;
      object-fit: cover;
    }
  }
  